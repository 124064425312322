//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import Error from './components/Error';
import Navigation from './components/Navigation';
import MSSwitch from './components/MS';
import MXSecurity from './components/MX';
import MIAnalytics from './components/MI';
import MRWireless from './components/MR';
import MVCamera from './components/MV';
import MTSensor from './components/MT';
import MGGateway from './components/MG';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishVisible: null,
      changeLogVisible: null,
    };
  }

  render() {
    return (<HashRouter>
      <div>
        <Navigation />
        <Switch>
          <Route path="/" component={Home} exact="exact" />
          <Route path="/ms" component={MSSwitch} />
          <Route path="/mx" component={MXSecurity} />
          <Route path="/mi" component={MIAnalytics} />
          <Route path="/mr" component={MRWireless} />
          <Route path="/mg" component={MGGateway} />
          <Route path="/mv" component={MVCamera} />
          <Route path="/mt" component={MTSensor} />
          <Route component={Error} />
        </Switch>

        <div className='makeAWishContainer'>
          <div className='makeAWish' onClick={this.state.wishVisible != true
            ? () => this.setState({ wishVisible: true })
            : () => this.setState({ wishVisible: false })}>
            Feedback
          </div>
        </div>
        <div className={this.state.wishVisible == true
          ? "wishContainer"
          : "invisible"}>
          <form className="wishForm" action="https://formspree.io/mnqvrplg" method="POST">

            <div class="group">
              <input className="wishInput" autocomplete="off" type="text" name="name" required="required" />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label className="wishLabel">Name</label>
            </div>

            <div class="group">
              <input className="wishInput" autocomplete="off" type="text" name="email" required="required" />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label className="wishLabel">Email</label>
            </div>

            <div class="group">
              <input className="wishInput" autocomplete="off" type="text" name="iwishthat" required="required" />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label className="wishLabel">Feedback</label>
            </div>

            <input className="makeAWish" type="submit" value="Send" />

          </form>
        </div>
        {/* <a href="https://www.buymeacoffee.com/yaleohh" target="_blank">
          <div className='makeAWishContainer'>
            <div className='makeAWish' onClick={this.state.wishVisible != true}>
              Buy me a coffee
            </div>
          </div>
        </a> */}

        <div className='designedByContainer'>
          <div className='designedBy2'><img src='/img/greenheart.png' className='greenheart2' /></div>
          <div className='designedBy'>Designed by Yale Ohh</div>
          <div className='designedBy2'><img src='/img/greenheart.png' className='greenheart' /></div>
          <div className='designedBy3'>Last Updated
          </div>
          <a className='changeLogContainer' onClick={this.state.changeLogVisible != true
            ? () => this.setState({ changeLogVisible: true })
            : () => this.setState({ changeLogVisible: false })}>
            07/17/2024
          </a>
        </div>
        <div className={this.state.changeLogVisible != true
          ? 'invisible'
          : 'changeLog'
        }>

          Change Log
          <br></br>
          <br></br>
          <p>07/17/2024: Fixed 9300 skus.</p>
          <p>06/23/2024: Updated and reordered MR models.</p>
          <p>05/30/2024: Removed transceiver options due to complexity and added MV features.</p>
          <p>03/26/2024: Fixed MG SKUs.</p>
          <p>03/05/2024: Fixed advanced MS license for 9300 series.</p>
          <p>02/29/2024: Added 9300X/L and MV13 and MV33.</p>
          <p>01/24/2024: Fixed 9300 uplink modules and ms130 licensing skus.</p>
          <p>01/19/2024: Added Z4C and fixed copy paste formatting for ordering information.</p>
          <p>12/06/2023: Added MS130R and simplified MS code readability.</p>
          <p>11/08/2023: Added CAT9300 and fixed MT15 details.</p>
          <p>11/07/2023: Added MT15.</p>
          <p>10/26/2023: Fixed MS130 SKUs.</p>
          <p>10/04/2023: Added MS130 family and fixed MG SKUs.</p>
          <p>07/18/2023: Added Ladning page subheaders.</p>
          <p>06/28/2023: Fixed landing page spacing.</p>
          <p>06/15/2023: Added MT40 to MT page.</p>
          <p>06/13/2023: Fixed home icons spacing.</p>
          <p>06/12/2023: Added MG page.</p>
          <p>06/09/2023: Updated landing page with new icons.</p>
          <p>06/08/2023: Added Meraki Display and Z4.</p>
          <p>03/15/2023: Added MV corner mount.</p>
          <p>12/01/2022: Added MT12 leak accessories.</p>
          <p>11/22/2022: Added links to documentation and installation guides.</p>
          <p>11/18/2022: Changed logic of MV resolution and storage filtering.</p>
          <p>10/10/2022: Added MR28 and MR78.</p>
          <p>09/20/2022: Simplified camera resolution options.</p>
          <p>09/16/2022: Added gen 3 cameras.</p>
          <p>08/02/2022: Added MR CW series.</p>
          <p>04/04/2022: Added MT power options.</p>
          <p>04/01/2022: Added MT14, MT30, and updated MR models</p>
          <p>02/23/2022: Updated MX67/68 speeds. Thank you for the catch Austin!</p>
          <p>12/20/2021: Fixed 390 sku</p>
          <p>11/29/2021: Updated title headers and addded MT gateway information</p>
          <p>11/16/2021: Added MV52</p>
          <p>11/15/2021: Updated MX transceiver filters</p>
          <p>10/22/2021: Updated MS transceiver filters</p>
          <p>09/22/2021: Added vMX models</p>
          <p>07/29/2021: Fixed SFP options</p>
          <p>07/07/2021: Added MV2 and MT11</p>
          <p>06/09/2021: Added following MX models: MX75, MX85, MX95, MX105</p>
          <p>04/23/2021: Added MV mounts and fixed mgig option bug</p>
          <p>02/19/2021: Fixed option bubble color bug</p>
          <p>02/03/2021: Fixed MR enterprise license SKU</p>
          <p>01/30/2021: Fixed wish form, updated mx sizing, and fixed various bugs</p>
          <p>01/29/2021: Added dark mode</p>
          <p>12/22/2020: Updated verbiage of filters and added resolution to MV filters</p>
          <p>12/15/2020: Updated MR models (MR44, MR45)</p>
          <p>12/05/2020: Started MT sizing guide and fixed scaling issues</p>
          <p>12/04/2020: Condensed CSS file</p>
          <p>11/23/2020: Fixed SKU typos</p>
          <p>11/02/2020: Added MV Sense and changed Cloud Archive filter scheme</p>
          <p>10/15/2020: Added default license SKUs</p>
          <p>10/15/2020: Added MI sizing page</p>
          <p>10/10/2020: Added MV licensing filter and optimized storage functions</p>
          <p>09/30/2020: Added MV licnensing information and cloud storage options</p>
          <p>09/29/2020: Added MV filter bubbles</p>
          <p>09/24/2020: Added MV model details</p>
          <p>09/23/2020: Made MV sizing tool</p>
          <p>09/21/2020: Fixed MR streams filter and MS390 access port options</p>
          <p>09/17/2020: Added StackPower Option and automated SKUs and made switches change img depending on model</p>
          <p>09/11/2020: Bug fixes</p>
          <p>09/10/2020: Reorganized MS390 uplink module selections</p>
          <p>09/09/2020: Added Ordering Information for MS and license skus for MR</p>
          <p>09/04/2020: Stack cable bug fixes</p>
          <p>08/11/2020: Disabled MI options if SDWAN Plus is selected</p>
          <p>07/31/2020: Added MI licensing options and MX ordering information</p>
          <p>07/30/2020: Made font more bold</p>
          <p>07/29/2020: Created landing page and fixed window resize issues</p>
          <p>07/23/2020: Added antenna and mount options for outdoor APs</p>
          <p>07/23/2020: Added antenna and mount options for APs</p>
          <p>07/15/2020: Fixed react history issue by using hash router instead of browser router</p>
          <p>07/03/2020: Created MR sizing tool</p>
          <p>07/01/2020: Fixed MX model client count numbers</p>
          <p>06/12/2020: Added MS390 optional accessories</p>
          <p>06/12/2020: Replaced specific MX feaures license options</p>
          <p>06/11/2020: Added PoE information to MS models</p>
          <p>06/10/2020: Added 'Included PSU' information to MS models</p>
          <p>06/10/2020: Created this Change Log</p>
          <br></br>
          <br></br>
          Roadmap
          <br></br>
          <br></br>
          <p>- Add flex stacking as a feature filter in MS page</p>
          <p>- Add MS Port Count Filter</p>
        </div>
      </div>
    </HashRouter>);
  }
}

export default App;
