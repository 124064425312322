//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';

var devices = [
  {
    model: 'CW9162',
    standard: '6E',
    streams: 4,
    outdoor: false,
    antennas: false,
    mgig: true
  },
  {
    model: 'CW9163E',
    standard: '6E',
    streams: 2,
    outdoor: true,
    antennas: true,
    mgig: true
  },
  {
    model: 'CW9164',
    standard: '6E',
    streams: 4,
    outdoor: false,
    antennas: false,
    mgig: true
  },
  {
    model: 'CW9166',
    standard: '6E',
    streams: 4,
    outdoor: false,
    antennas: false,
    mgig: true
  },
  {
    model: 'CW9166D1',
    standard: '6E',
    streams: 4,
    outdoor: false,
    antennas: false,
    mgig: true
  },

  // {model: 'MR20',
  //   standard: 5,
  //   streams: 2,
  //   outdoor: false,
  //   antennas: false,
  //   mgig: false}, 
  // {
  //   model: 'MR30H',
  //   standard: 5,
  //   streams: 2,
  //   outdoor: false,
  //   antennas: false,
  //   mgig: false
  // }, 

  {
    model: 'MR28',
    standard: '6',
    streams: 2,
    outdoor: false,
    antennas: false,
    mgig: false
  },
  {
    model: 'MR36',
    standard: 6,
    streams: 2,
    outdoor: false,
    antennas: false,
    mgig: false
  }, {
    model: 'MR36H',
    standard: 6,
    streams: 2,
    outdoor: false,
    antennas: false,
    mgig: false
  },

  // {
  //   model: 'MR42',
  //   standard: 5,
  //   streams: 3,
  //   outdoor: false,
  //   antennas: false,
  //   mgig: false
  // },

  // {
  //   model: 'MR42E',
  //   standard: 5,
  //   streams: 3,
  //   outdoor: false,
  //   antennas: true,
  //   mgig: false
  // },

  {
    model: 'MR44',
    standard: 6,
    streams: 4,
    outdoor: false,
    antennas: false,
    mgig: true
  },

  // {
  //   model: 'MR45',
  //   standard: 6,
  //   streams: 4,
  //   outdoor: false,
  //   antennas: false,
  //   mgig: true
  // },

  {
    model: 'MR46',
    standard: 6,
    streams: 4,
    outdoor: false,
    antennas: false,
    mgig: true
  }, {
    model: 'MR46E',
    standard: 6,
    streams: 4,
    outdoor: false,
    antennas: true,
    mgig: true
  },

  // {
  //   model: 'MR52',
  //   standard: 5,
  //   streams: 4,
  //   outdoor: false,
  //   antennas: false,
  //   mgig: false
  // },

  // {
  //   model: 'MR53',
  //   standard: 5,
  //   streams: 4,
  //   outdoor: false,
  //   antennas: false,
  //   mgig: true
  // }, {
  //   model: 'MR53E',
  //   standard: 5,
  //   streams: 4,
  //   outdoor: false,
  //   antennas: true,
  //   mgig: true
  // },

  {
    model: 'MR56',
    standard: 6,
    streams: 8,
    outdoor: false,
    antennas: false,
    mgig: true
  }, {
    model: 'MR57',
    standard: '6E',
    streams: 4,
    outdoor: false,
    antennas: false,
    mgig: true
  },
  // {
  //   model: 'MR70',
  //   standard: 5,
  //   streams: 2,
  //   outdoor: true,
  //   antennas: false,
  //   mgig: false
  // },

  // {
  //   model: 'MR74',
  //   standard: 5,
  //   streams: 2,
  //   outdoor: true,
  //   antennas: true,
  //   mgig: false
  // },

  {
    model: 'MR76',
    standard: 6,
    streams: 2,
    outdoor: true,
    antennas: true,
    mgig: false
  },

  {
    model: 'MR78',
    standard: '6',
    streams: 2,
    outdoor: true,
    antennas: false,
    mgig: false
  },

  // {
  //   model: 'MR84',
  //   standard: 5,
  //   streams: 4,
  //   outdoor: true,
  //   antennas: true,
  //   mgig: true
  // },

  {
    model: 'MR86',
    standard: 6,
    streams: 4,
    outdoor: true,
    antennas: true,
    mgig: true
  }
]

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ANTENNAS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const dipoleAntenna = {
  model: 'Dipole Antenna',
  sku: 'MA-ANT-3-A',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'The Dipole antenna is often used in low-density deployments like schools and hotels to provide basic connectivity.'
}

const bendableDipoleAntenna = {
  model: 'Bendable Dipole Antenna',
  sku: 'MA-ANT-3-B',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'The Dipole antenna is often used in low-density deployments like schools and hotels to provide basic connectivity.'
}

const panelOmniAntenna = {
  model: 'Panel Omni Antenna',
  sku: 'MA-ANT-3-C',
  mount1: 'Horizontal Mounting Bracket',
  mountSku1: 'MA-MNT-ANT-3',
  mount2: 'Vertical Mounting Bracket',
  mountSku2: 'MA-MNT-ANT-4',
  description: 'The Panel Omni antenna is often used in low density deployments like schools and hotels to provide basic low performance connectivity.'
}

const downtiltPanelOmniAntenna = {
  model: 'Downtilt Panel Omni Antenna',
  sku: 'MA-ANT-3-D',
  mount1: 'Horizontal Mounting Bracket',
  mountSku1: 'MA-MNT-ANT-3',
  mount2: 'Vertical Mounting Bracket',
  mountSku2: 'MA-MNT-ANT-4',
  description: 'The Downtilt Panel Omnidirectional antenna is often used in medium-density deployments like cube office space and vestibules.'
}

const widePatchAntenna = {
  model: 'Wide Patch Antenna',
  sku: 'MA-ANT-3-E',
  mount1: 'Standard Mounting Arm',
  mountSku1: 'MA-MNT-ANT-1',
  mount2: 'Long Mounting Arm',
  mountSku2: 'MA-MNT-ANT-2',
  description: 'The Wide Patch antenna is often used in vestibules with very high ceilings but few devices connecting and lecture halls.'
}

const narrowPatchAntenna = {
  model: 'Narrow Patch Antenna',
  sku: 'MA-ANT-3-F',
  mount1: 'Standard Mounting Arm',
  mountSku1: 'MA-MNT-ANT-1',
  mount2: 'Long Mounting Arm',
  mountSku2: 'MA-MNT-ANT-2',
  description: 'The Narrow Patch antenna is often used in large lecture halls/stadiums as well as event venue centers.'
}

const dbSectorAntenna = {
  model: 'Dual-Band Sector Antenna',
  sku: 'MA-ANT-27',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'The Dual-Band Patch Antenna is ideal for providing client coverage over focused areas or creating backhaul links.'
}

const dbPatchAntenna = {
  model: 'Dual-Band Patch Antenna',
  sku: 'MA-ANT-25',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'The Dual-Band Patch Antenna is ideal for providing client coverage over focused areas or creating backhaul links.'
}

const dbOmniAntenna = {
  model: 'Dual-Band Omni Antenna',
  sku: 'MA-ANT-20',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'The Dual-Band Omni Antenna is a versatile antenna and is suitable for a broad range of applications.'
}

const fiveSectorAntenna = {
  model: '5GHz Sector Antenna',
  sku: 'MA-ANT-21',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'The 5GHz Sector Antenna is ideal for providing client coverage over focused areas or creating backhaul links.'
}

const twoSectorAntenna = {
  model: '2.4GHz Sector Antenna',
  sku: 'MA-ANT-23',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'The 2.4GHz Sector Antenna is ideal for providing client coverage over focused areas.'
}

const directionalPatch9163E = {
  model: 'Directional Patch Antenna',
  sku: 'CW-ANT-D1-NS-00',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'Outdoor Tri-band Wi-Fi 6E directional antenna. Includes CW-MNT-ART2-00 articulating mount.'
}

const gps9163E = {
  model: 'GPS/GNSS Antenna',
  sku: 'CW-ANT-GPS2-S-00',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'Optional external GPS/GNSS antenna.'
}

const omnidirectional9163E = {
  model: 'Omnidirectional Dipole',
  sku: 'CW-ANT-O1-NS-00',
  mount1: null,
  mountSku1: null,
  mount2: null,
  mountSku2: null,
  description: 'Omnidirectional external antenna to provide broad coverage.'
}

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ STATE VARIABLES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

class MRPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedSwitchModel: null,
      clickedSwitchModelDetails: null,
      switchModel: null,
      standard: null,
      streams: null,
      outdoor: null,
      antennas: null,
      mgig: null,
      license: null,
      licenseTerm: null,

      clickedAntenna: null,
      mount1: null,
      mount2: null,
      mountSku1: null,
      mountSku2: null,
      clickedMount: null,
      clickedMountSku: null,

      streamsbubble: null
    };
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SWITCHES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderAntennaInfo(model, sku, mountSku1, mount1, mountSku2, mount2, summary) {
    return (<div>
      <div onClick={this.state.clickedAntenna != model
        ? () => this.setState({
          clickedAntenna: model,
          clickedAntennaSku: sku,
          mount1: mount1,
          mount2: mount2,
          mountSku1: mountSku1,
          mountSku2: mountSku2,
          clickedAntennaSummary: summary,
          clickedMount: null,
          clickedMountSku: null
        })
        : () => this.setState({
          clickedAntenna: null,
          clickedAntennaSku: null,
          mount1: null,
          mount2: null,
          clickedAntennaSummary: null,
          clickedMount: null,
          clickedMountSku: null
        })} className={this.state.clickedAntenna == model
          ? 'antennaPicsCircleSelected'
          : 'antennaPicsCircle'}>
        <p className='antennaPics'>{model}</p>
      </div>
    </div>)
  }

  renderAntennaInfoSelected(header, info, info2) {
    return (<div>
      {
        info != null
          ? <p>
            <h6 className='switchInfoHeader'>{header}</h6>
            {info}
            {info2}</p>
          : <p>
            <h6 className='switchInfoHeader'>{header}</h6>
            N/A</p>
      }
    </div>)
  }

  renderMountInfo(mount, sku) {
    return (<div>
      <div onClick={this.state.clickedMount != mount
        ? () => this.setState({ clickedMount: mount, clickedMountSku: sku })
        : () => this.setState({ clickedMount: null, clickedMountSku: null })} className={this.state.clickedMount == mount
          ? 'antennaPicsCircleSelected'
          : 'antennaPicsCircle'}>
        <p className='antennaPics'>{mount}</p>
      </div>
    </div>)
  }

  renderSwitchInfo(header, switchModels) {
    return (<div>
      <p>
        <h6 className='switchInfoHeader'>{header + ': '}</h6>
        {switchModels}</p>
    </div>)
  }

  renderSwitchModelDetailsClicked() {
    return (<div>
      {
        this.state.clickedSwitchModel != null
          ? <div className='secondaryOptionsContainer secondaryOptionsContainerModel'>
            <h4>{this.state.clickedSwitchModel}</h4>
            <h5 className="commaToNewLine">{this.state.clickedSwitchModelDetails.replace(/~/g, "\n")}</h5>
          </div>
          : ""
      }
    </div>)
  }

  renderSecurityLicenseSKU() {
    if (this.state.license === 'ADV' && this.state.licenseTerm == null) {
      return <p>{'1 x LIC-MR-' + this.state.license + '-xYR (x = 1, 3, 5, 7, 10 years)'}</p>;
    } else if (this.state.license === 'ENT' && this.state.licenseTerm == null) {
      return <p>{'1 x LIC-' + this.state.license + '-xYR (x = 1, 3, 5, 7, 10 years)'}</p>;
    } else if (this.state.license === 'ADV' && this.state.licenseTerm != null) {
      return <p>{'1 x LIC-MR-' + this.state.license + '-' + this.state.licenseTerm + 'YR'}</p>;
    } else if (this.state.license === 'ENT' && this.state.licenseTerm != null) {
      return <p>{'1 x LIC-' + this.state.license + '-' + this.state.licenseTerm + 'YR'}</p>;
    } else {
      return null;
    }
  }

  renderClickedAntennaSku() {
    if (this.state.clickedAntennaSku != null) {
      return <p>{this.state.clickedAntennaSku}</p>;
    } else {
      return null;
    }
  }

  renderClickedMountSku() {
    if (this.state.clickedMountSku != null) {
      return <p>{this.state.clickedMountSku}</p>;
    } else {
      return null;
    }
  }

  renderSwitchModel(model) {
    return (<div className={model + ' switchPictureContainer'}>
      <div onClick={this.state.switchModel != model
        ? () => this.setState({
          switchModel: model,
          clickedSwitchModel: null,
          clickedAntenna: null,
          clickedAntennaSku: null,
          mount1: null,
          mount2: null,
          clickedAntennaSummary: null,
          clickedMount: null,
          clickedMountSku: null
        })
        : () => this.setState({
          switchModel: null,
          clickedAntenna: null,
          clickedAntennaSku: null,
          mount1: null,
          mount2: null,
          clickedAntennaSummary: null,
          clickedMount: null,
          clickedMountSku: null
        })} className={'switchModel' + model}>
        <h6 className={this.state.switchModel == model
          ? 'selectedSwitchHeader'
          : ''}>{model}</h6>
        {
          model == 'MR56' || model == 'MR46E' || model == 'MR44' || model == 'MR45' || model == 'MR46' || model == 'MR36' || model == 'MR76' || model == 'MR86'
            ? <img src={'/img/mr/' + model + '.png'} alt={model} className='modelIconLarge modelIcon' />
            : model == 'MR30H' || model == 'MR70' || model == 'MR36H' || model == 'CW9162' || model == 'CW9164' || model == 'CW9166' || model == 'CW9163E' || model == 'CW9166D1'
              ? <img src={'/img/mr/' + model + '.png'} alt={model} className='modelIconXSmall modelIcon' />
              : model == 'MR78'
                ? <img src={'/img/mr/' + model + '.png'} alt={model} className='modelIconXXSmall modelIcon' />
                : <img src={'/img/mr/' + model + '.png'} alt={model} className='modelIconMedium modelIcon' />
        }
      </div>

      <div className={'switchInfo'}>
        {
          this.state.switchModel == 'MR28' && model == this.state.switchModel
            ? <div>
              <div className='switchInfoSelected'>
                {this.renderSwitchModelDetailsClicked()}
                {this.renderSwitchInfo('Environment', 'Indoor')}
                {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                <p>1 x MR28-HW</p>
                {this.renderSecurityLicenseSKU()}
              </div>
              <div className='datasheetContainer'>
                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR28_Datasheet">Datasheet</a>
                <p className='datasheetDivider'> | </p>
                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR28_Installation_Guide">Installation</a>
              </div>
            </div>
            : this.state.switchModel == 'MR78' && model == this.state.switchModel
              ? <div><div className='switchInfoSelected'>
                {this.renderSwitchModelDetailsClicked()}
                {this.renderSwitchInfo('Environment', 'Outdoor')}
                {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                <p>1 x MR78-HW</p>
                {this.renderSecurityLicenseSKU()}
              </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR78_Datasheet">Datasheet</a>
                  <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MX/MX_Installation_Guides/MX67%2F%2FMX68_Installation_Guide">Installation</a>
                </div>
              </div>

              : this.state.switchModel == 'CW9162' && model == this.state.switchModel
                ? <div>
                  <div className='switchInfoSelected'>
                    {this.renderSwitchModelDetailsClicked()}
                    {this.renderSwitchInfo('Environment', 'Indoor')}
                    {this.renderSwitchInfo('WiFi Standard', '802.11ax compatible')}
                    {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                    <p>1 x CW9162I-MR</p>
                    {this.renderSecurityLicenseSKU()}
                  </div>
                  <div className='datasheetContainer'>
                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/CW9162_Datasheet">Datasheet</a>
                    <p className='datasheetDivider'> | </p>
                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/CW9162_Installation_Guide">Installation</a>
                  </div>
                </div>

                : this.state.switchModel == 'CW9163E' && model == this.state.switchModel
                  ? <div>
                    <div className='switchInfoSelected'>
                      {this.renderSwitchModelDetailsClicked()}
                      {this.renderSwitchInfo('Environment', 'Outdoor')}
                      {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                      {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                      {this.renderSwitchInfo('Please select an antenna', '')}
                      <div className='antennaPicsContainerContainer'>
                        {this.renderAntennaInfo(directionalPatch9163E.model, '1 x ' + directionalPatch9163E.sku + '6', '1 x ' + directionalPatch9163E.mountSku1, directionalPatch9163E.mount1, '1 x ' + directionalPatch9163E.mountSku2, directionalPatch9163E.mount2, directionalPatch9163E.description)}
                        {this.renderAntennaInfo(gps9163E.model, '1 x ' + gps9163E.sku + '6', '1 x ' + gps9163E.mountSku1, gps9163E.mount1, '1 x ' + gps9163E.mountSku2, gps9163E.mount2, gps9163E.description)}
                        {this.renderAntennaInfo(omnidirectional9163E.model, '1 x ' + omnidirectional9163E.sku + '6', '1 x ' + omnidirectional9163E.mountSku1, omnidirectional9163E.mount1, '1 x ' + omnidirectional9163E.mountSku2, omnidirectional9163E.mount2, omnidirectional9163E.description)}
                      </div>
                      <p className='antennaSummary'>{this.state.clickedAntennaSummary}</p>

                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                      <p>1 x CW9163E-MR</p>
                      {this.renderSecurityLicenseSKU()}
                      {this.renderClickedAntennaSku()}
                    </div>
                    <div className='datasheetContainer'>
                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/CW9163E_Datasheet">Datasheet</a>
                      <p className='datasheetDivider'> | </p>
                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/CW9163E-MR_Installation_Guide">Installation</a>
                    </div>
                  </div>

                  : this.state.switchModel == 'CW9164' && model == this.state.switchModel
                    ? <div>
                      <div className='switchInfoSelected'>
                        {this.renderSwitchModelDetailsClicked()}
                        {this.renderSwitchInfo('Environment', 'Indoor')}
                        {this.renderSwitchInfo('WiFi Standard', '802.11ax compatible')}
                        {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2 (2.4GHz) + 4 x 4 : 4 (5GHz)')}
                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                        <p>1 x CW9164I-MR</p>
                        {this.renderSecurityLicenseSKU()}
                      </div>
                      <div className='datasheetContainer'>
                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/CW9164_Datasheet">Datasheet</a>
                        <p className='datasheetDivider'> | </p>
                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/CW9164_Installation_Guide">Installation</a>
                      </div>
                    </div>
                    : this.state.switchModel == 'CW9166' && model == this.state.switchModel
                      ? <div>
                        <div className='switchInfoSelected'>
                          {this.renderSwitchModelDetailsClicked()}
                          {this.renderSwitchInfo('Environment', 'Indoor')}
                          {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                          {this.renderSwitchInfo('Spatial Streams', '4 x 4 : 4')}
                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                          <p>1 x CW9166I-MR</p>
                          {this.renderSecurityLicenseSKU()}
                        </div>
                        <div className='datasheetContainer'>
                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/CW9166_Datasheet">Datasheet</a>
                          <p className='datasheetDivider'> | </p>
                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/CW9166_Installation_Guide">Installation</a>
                        </div>
                      </div>

                      : this.state.switchModel == 'CW9166D1' && model == this.state.switchModel
                        ? <div>
                          <div className='switchInfoSelected'>
                            {this.renderSwitchModelDetailsClicked()}
                            {this.renderSwitchInfo('Environment', 'Indoor')}
                            {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                            {this.renderSwitchInfo('Spatial Streams', '4 x 4 : 4')}
                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                            <p>1 x CW9166D1-MR</p>
                            {this.renderSecurityLicenseSKU()}
                          </div>
                          <div className='datasheetContainer'>
                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/CW9166_Datasheet">Datasheet</a>
                            <p className='datasheetDivider'> | </p>
                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/CW9166_Installation_Guide">Installation</a>
                          </div>
                        </div>

                        : this.state.switchModel == 'MR20' && model == this.state.switchModel
                          ? <div>
                            <div className='switchInfoSelected'>
                              {this.renderSwitchModelDetailsClicked()}
                              {this.renderSwitchInfo('Environment', 'Indoor')}
                              {this.renderSwitchInfo('WiFi Standard', '802.11ac Wave 2')}
                              {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                              <p>1 x MR20-HW</p>
                              {this.renderSecurityLicenseSKU()}
                            </div>
                            <div className='datasheetContainer'>
                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mr20-datasheet/?file">Datasheet</a>
                              <p className='datasheetDivider'> | </p>
                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR20_Installation_Guide">Installation</a>
                            </div>
                          </div>
                          : this.state.switchModel == 'MR30H' && model == this.state.switchModel
                            ? <div>
                              <div className='switchInfoSelected'>
                                {this.renderSwitchModelDetailsClicked()}
                                {this.renderSwitchInfo('Environment', 'Indoor')}
                                {this.renderSwitchInfo('WiFi Standard', '802.11ac Wave 2')}
                                {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                <p>1 x MR30H-HW</p>
                                {this.renderSecurityLicenseSKU()}
                              </div>
                              <div className='datasheetContainer'>
                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mr30h-datasheet/?file">Datasheet</a>
                                <p className='datasheetDivider'> | </p>
                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR30H_Installation_Guide">Installation</a>
                              </div>
                            </div>
                            : this.state.switchModel == 'MR36' && model == this.state.switchModel
                              ? <div>
                                <div className='switchInfoSelected'>
                                  {this.renderSwitchModelDetailsClicked()}
                                  {this.renderSwitchInfo('Environment', 'Indoor')}
                                  {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                  {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                  <p>1 x MR36-HW</p>
                                  {this.renderSecurityLicenseSKU()}
                                </div>
                                <div className='datasheetContainer'>
                                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR36_Datasheet">Datasheet</a>
                                  <p className='datasheetDivider'> | </p>
                                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR36_Installation_Guide">Installation</a>
                                </div>
                              </div>
                              : this.state.switchModel == 'MR36H' && model == this.state.switchModel
                                ? <div>
                                  <div className='switchInfoSelected'>
                                    {this.renderSwitchModelDetailsClicked()}
                                    {this.renderSwitchInfo('Environment', 'Indoor')}
                                    {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                    {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                    <p>1 x MR36H-HW</p>
                                    {this.renderSecurityLicenseSKU()}
                                  </div>
                                  <div className='datasheetContainer'>
                                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR36H_Datasheet">Datasheet</a>
                                    <p className='datasheetDivider'> | </p>
                                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR36H_Installation_Guide">Installation</a>
                                  </div>
                                </div>
                                : this.state.switchModel == 'MR44' && model == this.state.switchModel
                                  ? <div>
                                    <div className='switchInfoSelected'>
                                      {this.renderSwitchModelDetailsClicked()}
                                      {this.renderSwitchInfo('Environment', 'Indoor')}
                                      {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                      {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2 (2.4GHz) + 4 x 4 : 4 (5GHz)')}
                                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                      <p>1 x MR44-HW</p>
                                      {this.renderSecurityLicenseSKU()}
                                    </div>
                                    <div className='datasheetContainer'>
                                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR44_Datasheet">Datasheet</a>
                                      <p className='datasheetDivider'> | </p>
                                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR44_Installation_Guide">Installation</a>
                                    </div>
                                  </div>
                                  : this.state.switchModel == 'MR46' && model == this.state.switchModel
                                    ? <div>
                                      <div className='switchInfoSelected'>
                                        {this.renderSwitchModelDetailsClicked()}
                                        {this.renderSwitchInfo('Environment', 'Indoor')}
                                        {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                        {this.renderSwitchInfo('Spatial Streams', '4 x 4 : 4')}
                                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                        <p>1 x MR46-HW</p>
                                        {this.renderSecurityLicenseSKU()}
                                      </div>
                                      <div className='datasheetContainer'>
                                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR46_Datasheet">Datasheet</a>
                                        <p className='datasheetDivider'> | </p>
                                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR46_Installation_Guide">Installation</a>
                                      </div>
                                    </div>
                                    : this.state.switchModel == 'MR46E' && model == this.state.switchModel
                                      ? <div>
                                        <div className='switchInfoSelected'>
                                          {this.renderSwitchModelDetailsClicked()}
                                          {this.renderSwitchInfo('Environment', 'Indoor')}
                                          {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                          {this.renderSwitchInfo('Spatial Streams', '4 x 4 : 4')}
                                          {this.renderSwitchInfo('Please select an antenna', '')}
                                          <div className='antennaPicsContainerContainer'>
                                            {this.renderAntennaInfo(dipoleAntenna.model, '1 x ' + dipoleAntenna.sku + '6', '1 x ' + dipoleAntenna.mountSku1, dipoleAntenna.mount1, '1 x ' + dipoleAntenna.mountSku2, dipoleAntenna.mount2, dipoleAntenna.description)}
                                            {this.renderAntennaInfo(bendableDipoleAntenna.model, '1 x ' + bendableDipoleAntenna.sku + '6', '1 x ' + bendableDipoleAntenna.mountSku1, bendableDipoleAntenna.mount1, '1 x ' + bendableDipoleAntenna.mountSku2, bendableDipoleAntenna.mount2, bendableDipoleAntenna.description)}
                                            {this.renderAntennaInfo(panelOmniAntenna.model, '1 x ' + panelOmniAntenna.sku + '6', '1 x ' + panelOmniAntenna.mountSku1, panelOmniAntenna.mount1, '1 x ' + panelOmniAntenna.mountSku2, panelOmniAntenna.mount2, panelOmniAntenna.description)}
                                            {this.renderAntennaInfo(downtiltPanelOmniAntenna.model, '1 x ' + downtiltPanelOmniAntenna.sku + '6', '1 x ' + downtiltPanelOmniAntenna.mountSku1, downtiltPanelOmniAntenna.mount1, '1 x ' + downtiltPanelOmniAntenna.mountSku2, downtiltPanelOmniAntenna.mount2, downtiltPanelOmniAntenna.description)}
                                            {this.renderAntennaInfo(widePatchAntenna.model, '1 x ' + widePatchAntenna.sku + '6', '1 x ' + widePatchAntenna.mountSku1, widePatchAntenna.mount1, '1 x ' + widePatchAntenna.mountSku2, widePatchAntenna.mount2, widePatchAntenna.description)}
                                            {this.renderAntennaInfo(narrowPatchAntenna.model, '1 x ' + narrowPatchAntenna.sku + '6', '1 x ' + narrowPatchAntenna.mountSku1, narrowPatchAntenna.mount1, '1 x ' + narrowPatchAntenna.mountSku2, narrowPatchAntenna.mount2, narrowPatchAntenna.description)}
                                          </div>
                                          <p className='antennaSummary'>{this.state.clickedAntennaSummary}</p>
                                          {
                                            this.state.clickedAntenna != null && this.state.mount1 != null
                                              ? <div>
                                                {this.renderSwitchInfo('Please select a mount')}
                                                <div className='antennaPicsContainerContainer'>
                                                  {this.renderMountInfo(this.state.mount1, this.state.mountSku1)}
                                                  {this.renderMountInfo(this.state.mount2, this.state.mountSku2)}
                                                </div>
                                              </div>
                                              : <div></div>
                                          }
                                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                          <p>1 x MR46E-HW</p>
                                          {this.renderSecurityLicenseSKU()}
                                          {this.renderClickedAntennaSku()}
                                          {this.renderClickedMountSku()}
                                        </div>
                                        <div className='datasheetContainer'>
                                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR46e_Datasheet">Datasheet</a>
                                          <p className='datasheetDivider'> | </p>
                                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR46e_Installation_Guide">Installation</a>
                                        </div>
                                      </div>
                                      : this.state.switchModel == 'MR56' && model == this.state.switchModel
                                        ? <div>
                                          <div className='switchInfoSelected'>
                                            {this.renderSwitchModelDetailsClicked()}
                                            {this.renderSwitchInfo('Environment', 'Indoor')}
                                            {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                            {this.renderSwitchInfo('Spatial Streams', '8 x 8 : 8')}
                                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                            <p>1 x MR56-HW</p>
                                            {this.renderSecurityLicenseSKU()}
                                          </div>
                                          <div className='datasheetContainer'>
                                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR56_Datasheet">Datasheet</a>
                                            <p className='datasheetDivider'> | </p>
                                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR56_Installation_Guide">Installation</a>
                                          </div>
                                        </div>
                                        : this.state.switchModel == 'MR57' && model == this.state.switchModel
                                          ? <div>
                                            <div className='switchInfoSelected'>
                                              {this.renderSwitchModelDetailsClicked()}
                                              {this.renderSwitchInfo('Environment', 'Indoor')}
                                              {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                              {this.renderSwitchInfo('Spatial Streams', '4 x 4 x 4 : 4')}
                                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                              <p>1 x MR57-HW</p>
                                              {this.renderSecurityLicenseSKU()}
                                            </div>
                                            <div className='datasheetContainer'>
                                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR57_Datasheet">Datasheet</a>
                                              <p className='datasheetDivider'> | </p>
                                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR57_Installation_Guide">Installation</a>
                                            </div>
                                          </div>
                                          : this.state.switchModel == 'MR70' && model == this.state.switchModel
                                            ? <div>
                                              <div className='switchInfoSelected'>
                                                {this.renderSwitchModelDetailsClicked()}
                                                {this.renderSwitchInfo('Environment', 'Outdoor')}
                                                {this.renderSwitchInfo('WiFi Standard', '802.11ac Wave 2')}
                                                {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                                                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                <p>1 x MR70-HW</p>
                                                {this.renderSecurityLicenseSKU()}
                                              </div>
                                              <div className='datasheetContainer'>
                                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/mr70-datasheet/?file">Datasheet</a>
                                                <p className='datasheetDivider'> | </p>
                                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR70_Installation_Guide">Installation</a>
                                              </div>
                                            </div>
                                            : this.state.switchModel == 'MR76' && model == this.state.switchModel
                                              ? <div>
                                                <div className='switchInfoSelected'>
                                                  {this.renderSwitchModelDetailsClicked()}
                                                  {this.renderSwitchInfo('Environment', 'Outdoor')}
                                                  {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                                  {this.renderSwitchInfo('Spatial Streams', '2 x 2 : 2')}
                                                  {this.renderSwitchInfo('Please select an antenna', '')}
                                                  <div className='antennaPicsContainerContainer'>
                                                    {this.renderAntennaInfo(dbSectorAntenna.model, '2 x ' + dbSectorAntenna.sku, '1 x ' + dbSectorAntenna.mountSku1, dbSectorAntenna.mount1, '1 x ' + dbSectorAntenna.mountSku2, dbSectorAntenna.mount2, dbSectorAntenna.description)}
                                                    {this.renderAntennaInfo(dbPatchAntenna.model, '2 x ' + dbPatchAntenna.sku, '1 x ' + dbPatchAntenna.mountSku1, dbPatchAntenna.mount1, '1 x ' + dbPatchAntenna.mountSku2, dbPatchAntenna.mount2, dbPatchAntenna.description)}
                                                    {this.renderAntennaInfo(dbOmniAntenna.model, '2 x ' + dbOmniAntenna.sku, '1 x ' + dbOmniAntenna.mountSku1, dbOmniAntenna.mount1, '1 x ' + dbOmniAntenna.mountSku2, dbOmniAntenna.mount2, dbOmniAntenna.description)}
                                                    {this.renderAntennaInfo(twoSectorAntenna.model, '2 x ' + twoSectorAntenna.sku, '1 x ' + twoSectorAntenna.mountSku1, twoSectorAntenna.mount1, '1 x ' + twoSectorAntenna.mountSku2, twoSectorAntenna.mount2, twoSectorAntenna.description)}
                                                    {this.renderAntennaInfo(fiveSectorAntenna.model, '2 x ' + fiveSectorAntenna.sku, '1 x ' + fiveSectorAntenna.mountSku1, fiveSectorAntenna.mount1, '1 x ' + fiveSectorAntenna.mountSku2, fiveSectorAntenna.mount2, fiveSectorAntenna.description)}
                                                  </div>
                                                  <p className='antennaSummary'>{this.state.clickedAntennaSummary}</p>
                                                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                  <p>1 x MR76-HW</p>
                                                  {this.renderSecurityLicenseSKU()}
                                                  {this.renderClickedAntennaSku()}
                                                  {this.renderClickedMountSku()}
                                                </div>
                                                <div className='datasheetContainer'>
                                                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR76_Datasheet">Datasheet</a>
                                                  <p className='datasheetDivider'> | </p>
                                                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR76_Installation_Guide">Installation</a>
                                                </div>
                                              </div>
                                              : this.state.switchModel == 'MR86' && model == this.state.switchModel
                                                ? <div>
                                                  <div className='switchInfoSelected'>
                                                    {this.renderSwitchModelDetailsClicked()}
                                                    {this.renderSwitchInfo('Environment', 'Outdoor')}
                                                    {this.renderSwitchInfo('WiFi Standard', '802.11ax')}
                                                    {this.renderSwitchInfo('Spatial Streams', '4 x 4 : 4')}
                                                    {this.renderSwitchInfo('Please select an antenna', '')}
                                                    <div className='antennaPicsContainerContainer'>
                                                      {this.renderAntennaInfo(dbSectorAntenna.model, '2 x ' + dbSectorAntenna.sku, '1 x ' + dbSectorAntenna.mountSku1, dbSectorAntenna.mount1, '1 x ' + dbSectorAntenna.mountSku2, dbSectorAntenna.mount2, dbSectorAntenna.description)}
                                                      {this.renderAntennaInfo(dbPatchAntenna.model, '2 x ' + dbPatchAntenna.sku, '1 x ' + dbPatchAntenna.mountSku1, dbPatchAntenna.mount1, '1 x ' + dbPatchAntenna.mountSku2, dbPatchAntenna.mount2, dbPatchAntenna.description)}
                                                      {this.renderAntennaInfo(dbOmniAntenna.model, '2 x ' + dbOmniAntenna.sku, '1 x ' + dbOmniAntenna.mountSku1, dbOmniAntenna.mount1, '1 x ' + dbOmniAntenna.mountSku2, dbOmniAntenna.mount2, dbOmniAntenna.description)}
                                                      {this.renderAntennaInfo(twoSectorAntenna.model, '2 x ' + twoSectorAntenna.sku, '1 x ' + twoSectorAntenna.mountSku1, twoSectorAntenna.mount1, '1 x ' + twoSectorAntenna.mountSku2, twoSectorAntenna.mount2, twoSectorAntenna.description)}
                                                      {this.renderAntennaInfo(fiveSectorAntenna.model, '2 x ' + fiveSectorAntenna.sku, '1 x ' + fiveSectorAntenna.mountSku1, fiveSectorAntenna.mount1, '1 x ' + fiveSectorAntenna.mountSku2, fiveSectorAntenna.mount2, fiveSectorAntenna.description)}
                                                    </div>
                                                    <p className='antennaSummary'>{this.state.clickedAntennaSummary}</p>
                                                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                    <p>1 x MR86-HW</p>
                                                    {this.renderSecurityLicenseSKU()}
                                                    {this.renderClickedAntennaSku()}
                                                    {this.renderClickedMountSku()}
                                                  </div>
                                                  <div className='datasheetContainer'>
                                                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Overview_and_Specifications/MR86_Datasheet">Datasheet</a>
                                                    <p className='datasheetDivider'> | </p>
                                                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MR/MR_Installation_Guides/MR86_Installation_Guide">Installation</a>
                                                  </div>
                                                </div>
                                                : ('')
        }
      </div>
    </div >)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ PICKING LOGIC @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  devicePickingLogic() {
    return (<div>
      {
        (<div>
          {
            devices.map(
              deviceModel => this.state.outdoor != null && deviceModel.outdoor != this.state.outdoor
                ? ''
                : this.state.standard != null && deviceModel.standard != this.state.standard
                  ? ''
                  : this.state.streams != null && deviceModel.streams != this.state.streams
                    ? ''
                    : this.state.antennas != null && deviceModel.antennas != this.state.antennas
                      ? ''
                      : this.renderSwitchModel(deviceModel.model))
          }
        </div>)
      }
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ BUBBLES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  optionBubbleLogic() {
    return (<div>
      {
        this.state.outdoor != null
          ? <div className='optionBubble' onClick={() => this.setState({ outdoor: null })}>{
            this.state.outdoor
              ? 'Outdoor'
              : 'Indoor'
          }</div>
          : ''
      }
      {
        this.state.standard != null
          ? <div className='optionBubble' onClick={() => this.setState({ standard: null })}>{'WiFi ' + this.state.standard}</div>
          : ''
      }
      {
        this.state.streams != null
          ? <div className='optionBubble' onClick={() => this.setState({ streams: null })}>{this.state.streamsbubble}</div>
          : ''
      }

      {
        this.state.antennas != null
          ? <div className='optionBubble' onClick={() => this.setState({ antennas: null })}>{
            this.state.antennas
              ? 'External Antennas'
              : 'Internal Antennas'
          }</div>
          : ''
      }
      {
        this.state.license != null && this.state.licenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ license: null, licenseTerm: null })}>{'License: ' + this.state.license + ' ' + this.state.licenseTerm + ' YR'}</div>
          : this.state.license != null
            ? <div className='optionBubble' onClick={() => this.setState({ license: null, licenseTerm: null })}>{'License: ' + this.state.license}</div>
            : ''
      }
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ OPTIONS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderOptionIndoor() {
    return (<div>
      <div onClick={(
      ) => this.state.outdoor != false
          ? this.setState({ outdoor: false })
          : this.setState({ outdoor: null })} className={this.state.outdoor == false
            ? 'optionClicked'
            : 'option'}>
        Indoor
      </div>
    </div>)
  }
  renderOptionOutdoor() {
    return (<div>
      <div onClick={(
      ) => this.state.outdoor != true
          ? this.setState({ outdoor: true })
          : this.setState({ outdoor: null })} className={this.state.outdoor
            ? 'optionClicked'
            : 'option'}>
        Outdoor
      </div>
    </div>)
  }

  renderOptionWiFi6E() {
    return (<div>
      <div onClick={(
      ) => this.state.standard != '6E'
          ? this.setState({ standard: '6E' })
          : this.setState({ standard: null })} className={this.state.standard == '6E'
            ? 'optionClicked'
            : 'option'}>
        <div className='leftSide'>WiFi 6E</div>
        <div className='rightSide'>802.11ax</div>
      </div>
    </div>)
  }
  renderOptionWiFi6() {
    return (<div>
      <div onClick={(
      ) => this.state.standard != 6
          ? this.setState({ standard: 6 })
          : this.setState({ standard: null })} className={this.state.standard == 6
            ? 'optionClicked'
            : 'option'}>
        <div className='leftSide'>WiFi 6</div>
        <div className='rightSide'>802.11ax</div>
      </div>
    </div>)
  }
  renderOptionWiFi5() {
    return (<div>
      <div onClick={(
      ) => this.state.standard != 5
          ? this.setState({ standard: 5 })
          : this.setState({ standard: null })} className={this.state.standard == 5
            ? 'optionClicked'
            : 'option'}>
        <div className='leftSide'>WiFi 5</div>
        <div className='rightSide'>802.11ac Wave 2</div>
      </div>
    </div>)
  }

  renderOptionStreams2() {
    return (<div>
      <div onClick={(
      ) => this.state.streams != 2
          ? this.setState({ streams: 2, streamsbubble: '2 x 2 : 2' })
          : this.setState({ streams: null, streamsbubble: null })} className={this.state.streams == 2
            ? 'optionClicked'
            : 'option'}>
        2 x 2 : 2
      </div>
    </div>)
  }
  renderOptionStreams3() {
    return (<div>
      <div onClick={(
      ) => this.state.streams != 3
          ? this.setState({ streams: 3, streamsbubble: '3 x 3 : 3' })
          : this.setState({ streams: null, streamsbubble: null })} className={this.state.streams == 3
            ? 'optionClicked'
            : 'option'}>
        3 x 3 : 3
      </div>
    </div>)
  }
  renderOptionStreams4() {
    return (<div>
      <div onClick={(
      ) => this.state.streams != 4
          ? this.setState({ streams: 4, streamsbubble: '4 x 4 : 4' })
          : this.setState({ streams: null, streamsbubble: null })} className={this.state.streams == 4
            ? 'optionClicked'
            : 'option'}>
        4 x 4 : 4
      </div>
    </div>)
  }
  renderOptionStreams8() {
    return (<div>
      <div onClick={(
      ) => this.state.streams != 8
          ? this.setState({ streams: 8, streamsbubble: '8 x 8 : 8' })
          : this.setState({ streams: null, streamsbubble: null })} className={this.state.streams == 8
            ? 'optionClicked'
            : 'option'}>
        8 x 8 : 8
      </div>
    </div>)
  }

  // renderOptionStreams44() {
  //   return (<div>
  //     <div onClick={(
  //         ) => this.state.streams != 44
  //         ? this.setState({streams: 44, streamsbubble: '4 x 4 x 4 : 4'})
  //         : this.setState({streams: null, streamsbubble: null})} className={this.state.streams == 44
  //         ? 'optionClicked'
  //         : 'option'}>
  //       4 x 4 x 4 : 4
  //     </div>
  //   </div>)
  // }

  renderOptionAntennaYes() {
    return (<div>
      <div onClick={(
      ) => this.state.antennas != true
          ? this.setState({ antennas: true })
          : this.setState({ antennas: null })} className={this.state.antennas
            ? 'optionClicked'
            : 'option'}>
        Yes
      </div>
    </div>)
  }

  renderOptionAntennaNo() {
    return (<div>
      <div onClick={(
      ) => this.state.antennas != false
          ? this.setState({ antennas: false })
          : this.setState({ antennas: null })} className={this.state.antennas == false
            ? 'optionClicked'
            : 'option'}>
        No
      </div>
    </div>)
  }

  renderOptionEnterprise() {
    return (<div>
      <div onClick={(
      ) => this.state.license != 'ENT'
          ? this.setState({ license: 'ENT', licenseTerm: null, merakiInsight: null, miTerm: null })
          : this.setState({ license: null, licenseTerm: null })} className={this.state.license == 'ENT'
            ? 'optionClicked'
            : 'option'}>
        Enterprise
      </div>
      {
        this.state.license == 'ENT'
          ? (<div className='secondaryOptionsContainer'>
            <h4>Please select the duration for your Enterprise license (Years)</h4>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
              {this.renderSecondaryLicenseOptions(7)}
              {this.renderSecondaryLicenseOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderOptionAdvanced() {
    return (<div>
      <div onClick={(
      ) => this.state.license != 'ADV'
          ? this.setState({ license: 'ADV', licenseTerm: null, merakiInsight: null, miTerm: null })
          : this.setState({ license: null, licenseTerm: null })} className={this.state.license == 'ADV'
            ? 'optionClicked'
            : 'option'}>
        Advanced
      </div>
      {
        this.state.license == 'ADV'
          ? (<div className='secondaryOptionsContainer'>
            <h4>Please select the duration for your Advanced license (Years)</h4>
            <h5>The Advanced wireless license enables Meraki administrators to assign predefined Umbrella content filtering and security policies to an SSID or Group Policy directly from the dashboard.</h5>
            <h5>All access points in the dashboard network must be running MR 26.1+ firmware.</h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
              {this.renderSecondaryLicenseOptions(7)}
              {this.renderSecondaryLicenseOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderSecondaryLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.licenseTerm != years
        ? this.setState({ licenseTerm: years })
        : this.setState({ licenseTerm: null })} className={this.state.licenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      <h4>{years}</h4>
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ RENDER @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  render() {
    return (<div>

      <div className="form">

        <div className='leftForm'>
          {this.optionBubbleLogic()}
          {this.devicePickingLogic()}
        </div>

        <div className='rightFormContainer'>
          <div className='rightForm'>
            <div className="mainTitle">
              <h6 className='topSubHeader'>Meraki Networking</h6>
              <h1>MR Wireless</h1>
              <h3 className='bottomSubHeader'>
                The Meraki MR series is the world’s first enterprise-grade line of cloud-managed WLAN access points and designed for challenging environments.
              </h3>
            </div>

            <div>
              <h2>Choose your license type.</h2>
              <a href="https://documentation.meraki.com/MR/Other_Topics/Cisco_Meraki_and_Umbrella_Integration_-_MR_Advanced%2F%2FUpgrade_License" target="_blank">Learn more about our wireless licensing</a>
              {this.renderOptionEnterprise()}
              {this.renderOptionAdvanced()}
            </div>

            <div>
              <h2>Choose your deployment environment.</h2>
              {this.renderOptionIndoor()}
              {this.renderOptionOutdoor()}
            </div>

            <div>
              <h2>Choose your WiFi standard.</h2>
              {this.renderOptionWiFi6E()}
              {this.renderOptionWiFi6()}
              {this.renderOptionWiFi5()}
            </div>

            <div>
              <h2>Choose your antenna configuration.</h2>
              {this.renderOptionStreams2()}
              {this.renderOptionStreams3()}
              {this.renderOptionStreams4()}
              {this.renderOptionStreams8()}
            </div>

            <div>
              <h2>Do you need external antennas?</h2>
              {this.renderOptionAntennaYes()}
              {this.renderOptionAntennaNo()}
            </div>

          </div>
        </div>
      </div>
    </div>);
  }
}

// ========================================

export default MRPicker;
