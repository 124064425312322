//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';

var devices = [
  {
    useCase: 'Failover',
    model: 'MG21',
    modem: 'cat6',
    download: 300,
    upload: 50,
    simslots: 1,
    antenna: 'No'
  }, {
    useCase: 'Failover',
    model: 'MG21E',
    modem: 'cat6',
    download: 300,
    upload: 50,
    simslots: 1,
    antenna: 'Yes'
  }, {
    useCase: 'Failover',
    useCase2: 'Primary',
    model: 'MG41',
    modem: 'cat18',
    download: 1200,
    upload: 150,
    simslots: 2,
    antenna: 'No'
  }, {
    useCase: 'Failover',
    useCase2: 'Primary',
    model: 'MG41E',
    modem: 'cat18',
    download: 1200,
    upload: 150,
    simslots: 2,
    antenna: 'Yes'
  }, {
    useCase: 'Primary',
    model: 'MG51',
    modem: 'cat20',
    download: 2000,
    upload: 300,
    simslots: 2,
    antenna: 'No'
  }, {
    useCase: 'Primary',
    model: 'MG51E',
    modem: 'cat20',
    download: 2000,
    upload: 300,
    simslots: 2,
    antenna: 'Yes'
  }
]

//--------------------------------- State Variables ---------------------------------

class MGPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      type2: null,
      useCase: null,
      simslots: null,
      antenna: null,

      clickedDeviceModel: null,
      deviceModel: null,
      license: null,
      licenseTerm: null,

      clickedAccessory: null,
      clickedAccessorySku: null,
      clickedAccessorySummary: null,

      clickedPower: null,
      clickedPowerSku: null,
      clickedPowerSummary: null
    };
  }

  //--------------------------------- Devices ---------------------------------

  renderDeviceInfo(header, deviceModels) {
    if (header != null && deviceModels != null) {
      return (
        <p>
          <h6 className='switchInfoHeader'>{header + ': '}</h6>
          {deviceModels}
        </p>
      );
    } else {
      return null;
    }
  }

  renderAccessoryInfo(model, sku, summary) {
    if (this.state.clickedAccessory !== model) {
      return (
        <div
          onClick={() => this.setState({ clickedAccessory: model, clickedAccessorySku: sku, clickedAccessorySummary: summary })}
          className='antennaPicsCircle'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => this.setState({ clickedAccessory: null, clickedAccessorySku: null, clickedAccessorySummary: null })}
          className='antennaPicsCircleSelected'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    }
  }

  renderPowerInfo(model, sku, summary) {
    if (this.state.clickedPower !== model) {
      return (
        <div
          onClick={() => this.setState({ clickedPower: model, clickedPowerSku: sku, clickedPowerSummary: summary })}
          className='antennaPicsCircle'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => this.setState({ clickedPower: null, clickedPowerSku: null, clickedPowerSummary: null })}
          className='antennaPicsCircleSelected'
        >
          <p className='antennaPics'>{model}</p>
        </div>
      );
    }
  }

  renderLicenseSKU(model) {
    if (this.state.licenseTerm != null) {
      return '1 x LIC-' + model + '-ENT-' + this.state.licenseTerm + 'Y';
    } else {
      return '1 x LIC-' + model + '-ENT-xY (x = 1, 3, 5, 7, 10 years)';
    }
  }

  renderClickedPowerSku() {
    if (this.state.clickedPowerSku != null) {
      return <p>{this.state.clickedPowerSku}</p>;
    } else {
      return null;
    }
  }

  renderDeviceModel(model) {
    return (<div className={model + ' switchPictureContainer'}>
      <div onClick={this.state.deviceModel != model
        ? () => this.setState({ deviceModel: model, clickedDeviceModel: null, clickedAccessory: null, clickedAccessorySku: null, clickedAccessorySummary: null, clickedPower: null, clickedPowerSku: null, clickedPowerSummary: null })
        : () => this.setState({ deviceModel: null })} className={'deviceModel' + model}>
        <h6 className={this.state.deviceModel == model
          ? 'selectedSwitchHeader'
          : ''}>{model}</h6>
        <img src={'/img/mg/' + model + '.png'} alt={model} className='modelIconXSmall modelIcon' />
      </div>

      <div className={'switchInfo'}>
        {
          this.state.deviceModel == 'MG21' && model == this.state.deviceModel
            ? <div>
              <div className='switchInfoSelected'>
                {this.renderDeviceInfo('Best for', 'Failover connectivity')}
                {this.renderDeviceInfo('Modem', 'Cat 6')}
                {this.renderDeviceInfo('Throughput', '300 Mbps')}
                {this.renderDeviceInfo('SIM Slots', '1')}
                {this.renderDeviceInfo('LAN Ports', '2 x 1G')}
                {this.renderDeviceInfo('Antenna', 'Integrated')}

                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                <p>1 x MG21-HW-XX (XX = NA or WW)</p>
                {this.renderLicenseSKU('MG21')}
                {this.renderClickedPowerSku()}
              </div>
              <div className='datasheetContainer'>
                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Datasheets/MG21%2F%2F21E_Datasheet">Datasheet</a>
                <p className='datasheetDivider'> | </p>
                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Installation_Guides/MG21%2F%2FMG21E_Installation_Guide">Installation</a>
              </div>
            </div>
            : this.state.deviceModel == 'MG21E' && model == this.state.deviceModel
              ? <div>
                <div className='switchInfoSelected'>
                  {this.renderDeviceInfo('Best for', 'Failover connectivity')}
                  {this.renderDeviceInfo('Modem', 'Cat 6')}
                  {this.renderDeviceInfo('Throughput', '300 Mbps')}
                  {this.renderDeviceInfo('SIM Slots', '1')}
                  {this.renderDeviceInfo('LAN Ports', '2 x 1G')}
                  {this.renderDeviceInfo('Antenna', 'External')}

                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                  <p>1 x MG21E-HW-XX (XX = NA or WW)</p>
                  {this.renderLicenseSKU('MG21')}
                  {this.renderClickedPowerSku()}
                </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Datasheets/MG21%2F%2F21E_Datasheet">Datasheet</a>
                  <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Installation_Guides/MG21%2F%2FMG21E_Installation_Guide">Installation</a>
                </div>
              </div>
              : this.state.deviceModel == 'MG41' && model == this.state.deviceModel
                ? <div>
                  <div className='switchInfoSelected'>
                    {this.renderDeviceInfo('Best for', 'Critical failover or primary connectivity')}
                    {this.renderDeviceInfo('Modem', 'Cat 18')}
                    {this.renderDeviceInfo('Throughput', '1.2 Gbps')}
                    {this.renderDeviceInfo('SIM Slots', '2')}
                    {this.renderDeviceInfo('LAN Ports', '2 x 1G')}
                    {this.renderDeviceInfo('Antenna', 'Integrated')}

                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                    <p>1 x MG41-HW</p>
                    {this.renderLicenseSKU('MG41')}
                    {this.renderClickedPowerSku()}
                  </div>
                  <div className='datasheetContainer'>
                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Datasheets/MG41%2F%2F41E_Datasheet">Datasheet</a>
                    <p className='datasheetDivider'> | </p>
                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Installation_Guides/MG41_Installation_Guide">Installation</a>
                  </div>
                </div>

                : this.state.deviceModel == 'MG41E' && model == this.state.deviceModel
                  ? <div>
                    <div className='switchInfoSelected'>
                      {this.renderDeviceInfo('Best for', 'Critical failover or primary connectivity')}
                      {this.renderDeviceInfo('Modem', 'Cat 18')}
                      {this.renderDeviceInfo('Throughput', '1.2 Gbps')}
                      {this.renderDeviceInfo('SIM Slots', '2')}
                      {this.renderDeviceInfo('LAN Ports', '2 x 1G')}
                      {this.renderDeviceInfo('Antenna', 'External')}

                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                      <p>1 x MG41E-HW</p>
                      {this.renderLicenseSKU('MG41')}
                      {this.renderClickedPowerSku()}
                    </div>
                    <div className='datasheetContainer'>
                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Datasheets/MG41%2F%2F41E_Datasheet">Datasheet</a>
                      <p className='datasheetDivider'> | </p>
                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Installation_Guides/MG41_Installation_Guide">Installation</a>
                    </div>
                  </div>

                  : this.state.deviceModel == 'MG51' && model == this.state.deviceModel
                    ? <div>
                      <div className='switchInfoSelected'>
                        {this.renderDeviceInfo('Best for', 'Advanced primary connectivity')}
                        {this.renderDeviceInfo('Modem', 'Cat 20')}
                        {this.renderDeviceInfo('Throughput', '2 Gbps')}
                        {this.renderDeviceInfo('SIM Slots', '2')}
                        {this.renderDeviceInfo('LAN Ports', '2 x 2.5G')}
                        {this.renderDeviceInfo('Antenna', 'Integrated')}

                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                        <p>1 x MG51-HW</p>
                        {this.renderLicenseSKU('MG51')}
                        {this.renderClickedPowerSku()}
                      </div>
                      <div className='datasheetContainer'>
                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Datasheets/Page_TitleMG51%2F%2F51E_Technical_Specifications">Datasheet</a>
                        <p className='datasheetDivider'> | </p>
                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Installation_Guides/MG51%2F%2FMG51E_Installation_Guide">Installation</a>
                      </div>
                    </div>

                    : this.state.deviceModel == 'MG51E' && model == this.state.deviceModel
                      ? <div>
                        <div className='switchInfoSelected'>
                          {this.renderDeviceInfo('Best for', 'Advanced primary connectivity')}
                          {this.renderDeviceInfo('Modem', 'Cat 20')}
                          {this.renderDeviceInfo('Throughput', '2 Gbps')}
                          {this.renderDeviceInfo('SIM Slots', '2')}
                          {this.renderDeviceInfo('LAN Ports', '2 x 2.5G')}
                          {this.renderDeviceInfo('Antenna', 'External')}

                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                          <p>1 x MG51E-HW</p>
                          {this.renderLicenseSKU('MG51')}
                          {this.renderClickedPowerSku()}
                        </div>
                        <div className='datasheetContainer'>
                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Datasheets/Page_TitleMG51%2F%2F51E_Technical_Specifications">Datasheet</a>
                          <p className='datasheetDivider'> | </p>
                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MG/MG_Installation_Guides/MG51%2F%2FMG51E_Installation_Guide">Installation</a>
                        </div>
                      </div>

                      : ('')
        }
      </div>
    </div >)
  }

  //--------------------------------- Picking Logic ---------------------------------

  devicePickingLogic() {
    return (<div>
      {
        (<div>
          {
            devices.map(
              deviceModel => this.state.type != null && deviceModel.type != this.state.type && deviceModel.type2 != this.state.type
                ? ''
                : this.state.useCase != null && deviceModel.useCase != this.state.useCase && deviceModel.useCase2 != this.state.useCase
                  ? ''
                  : this.state.simslots != null && deviceModel.simslots != this.state.simslots
                    ? ''
                    : this.state.antenna != null && deviceModel.antenna != this.state.antenna
                      ? ''
                      : this.renderDeviceModel(deviceModel.model))
          }
        </div>)
      }
    </div>)
  }

  //--------------------------------- Bubbles ---------------------------------

  optionBubbleLogic() {
    return (<div>
      {
        this.state.licenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ licenseTerm: null })}>{this.state.licenseTerm + ' Year License'}</div>
          : null
      }

      {
        this.state.useCase == 'Failover'
          ? <div className='optionBubble' onClick={() => this.setState({ useCase: null })}>Failover</div>
          : this.state.useCase == 'Primary'
            ? <div className='optionBubble' onClick={() => this.setState({ useCase: null })}>Primary</div>
            : null
      }

      {
        this.state.simslots == '1'
          ? <div className='optionBubble' onClick={() => this.setState({ simslots: null })}>SIM Slots: 1</div>
          : this.state.simslots == '2'
            ? <div className='optionBubble' onClick={() => this.setState({ simslots: null })}>SIM Slots: 2</div>
            : null
      }
      {
        this.state.antenna == 'Yes'
          ? <div className='optionBubble' onClick={() => this.setState({ antenna: null })}>External Antennas</div>
          : this.state.antenna == 'No'
            ? <div className='optionBubble' onClick={() => this.setState({ antenna: null })}>Integrated Antennas</div>
            : null
      }

    </div>)
  }

  //--------------------------------- Options ---------------------------------

  renderSecondaryLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.licenseTerm != years
        ? this.setState({ licenseTerm: years })
        : this.setState({ licenseTerm: null })} className={this.state.licenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      {years}
    </div>)
  }

  renderOptionType(description, type) {
    return (<div onClick={(
    ) => this.state.type != type
        ? this.setState({ type: type })
        : this.setState({ type: null })} className={this.state.type == type
          ? 'optionClicked'
          : 'option'}>
      {description}
    </div>)
  }

  renderOptionUseCase(usecase) {
    return (<div onClick={(
    ) => this.state.useCase != usecase
        ? this.setState({ useCase: usecase })
        : this.setState({ useCase: null })} className={this.state.useCase == usecase
          ? 'optionClicked'
          : 'option'}>
      {usecase}
    </div>)
  }

  renderOptionSIM(slots) {
    return (<div onClick={(
    ) => this.state.simslots != slots
        ? this.setState({ simslots: slots })
        : this.setState({ simslots: null })} className={this.state.simslots == slots
          ? 'optionClicked'
          : 'option'}>
      {slots}
    </div>)
  }

  renderOptionAntenna(antenna) {
    return (<div onClick={(
    ) => this.state.antenna != antenna
        ? this.setState({ antenna: antenna })
        : this.setState({ antenna: null })} className={this.state.antenna == antenna
          ? 'optionClicked'
          : 'option'}>
      {antenna}
    </div>)
  }

  //--------------------------------- Render ---------------------------------
  render() {
    return (<div>

      <div className="form">

        <div className='leftForm'>
          {this.optionBubbleLogic()}
          {this.devicePickingLogic()}
        </div>

        <div className='rightFormContainer'>
          <div className='rightForm'>
            <div className="mainTitle">
              <h6 className='topSubHeader'>Meraki Networking</h6>
              <h1>MG Cellular Gateways</h1>
              <h3 className='bottomSubHeader'>
                Meraki MG cellular gateways enable high-speed, always-on cellular network connectivity for business-critical operations.
              </h3>
            </div>

            <div>
              <h2>Choose your license duration in years.
              </h2>
              <div className="secondaryOptionsContainer2">
                {this.renderSecondaryLicenseOptions(1)}
                {this.renderSecondaryLicenseOptions(3)}
                {this.renderSecondaryLicenseOptions(5)}
                {this.renderSecondaryLicenseOptions(7)}
                {this.renderSecondaryLicenseOptions(10)}
              </div>
            </div>

            <div>
              <h2>Choose your connection type.</h2>
              {this.renderOptionUseCase('Failover')}
              {this.renderOptionUseCase('Primary')}
            </div>

            <div>
              <h2>How many SIM slots do you need?</h2>
              {this.renderOptionSIM('1')}
              {this.renderOptionSIM('2')}
            </div>

            <div>
              <h2>Do you need external antennas.</h2>
              {this.renderOptionAntenna('Yes')}
              {this.renderOptionAntenna('No')}
            </div>

          </div>
        </div>
      </div>
    </div>);
  }
}

// ========================================

export default MGPicker;
